export const netfone = '/netfone';
export const unsecured = '/public';

export const API = {
  init: {
    initCall: `${unsecured}/initCall`,
  },
  contactUs: {
    send: `${unsecured}/contactUs/send`,
  },
  contactFranchisePartner: {
    send: `${unsecured}/contactFranchisePartner/send`,
  },
  resellerShop: {
    findAll: `${unsecured}/resellershop/findAll`,
  },
  termsAndCondition: {
    current: `${unsecured}/tnc/findAllCurrent`,
    previous: `${unsecured}/tnc/findAllPrevious`,
    download: `${unsecured}/tnc/download`,
  },
  services: {
    findAllMobile: `${unsecured}/service/mobile/findAll`,
    findByCRM: `${unsecured}/service/findAllByCrm`,
    findAllDsl: `${unsecured}/service/dsl/findAll`,
    list: `${unsecured}/service/mobile/list`,
    mobile: `${unsecured}/service/mobile`,
  },
  device: {
    findAll: `${unsecured}/device/findAll`,
    findByDeviceCode: `${unsecured}/device/findByDeviceCode`,
    findByDeviceName: `${unsecured}/device/findByDeviceName`,
    findAllFilter: `${unsecured}/device/findAllFilter`,
    findBaseDeviceDetailData: `${unsecured}/device/findBaseDeviceDetailData`,
    findCardDataForDeviceDetail: `${unsecured}/device/findCardDataForDeviceDetail`,
    findInstallmentsForDeviceDetail: `${unsecured}/device/findInstallmentsForDeviceDetail`,
    findForOrder: `${unsecured}/device/findForOrder`,
  },
  share: {
    registerClick: `${unsecured}/share/registerClick`,
    shortUrl: `${unsecured}/share/shortUrl`,
  },
  user: {
    loggedInUser: `${netfone}/user/loggedInUser`,
    update: `${netfone}/user/update`,
    findByNickname: `${unsecured}/user/findByNickname`,
  },
  content: {
    shareableContent: `${netfone}/content/findAllShareable`,
    shareContent: `${netfone}/content/share`,
  },
  brandContent: {
    shareableBrandContent: `${netfone}/brandContent/findAllShareable`,
  },
  statistics: {
    findAll: `${netfone}/statistics/findAll`,
  },
  order: `${unsecured}/order`,
  verification: {
    getVerificationCode: `${unsecured}/sms/request`,
    checkVerificationCode: `${unsecured}/sms/validate`,
  },
  zipCode: {
    getCityByZipCode: `${unsecured}/zip-cities/find-city-by-zipcode`,
  },
  activateSim: {
    validateSim: `${unsecured}/sim/validate/:simNumber`,
  },
  urlShortener: {
    shortenUrl: `${unsecured}/urlshortener/shorten`,
    findByShortUrl: `${unsecured}/urlshortener/findByShortUrl`,
  },
  faq: {
    findAll: `${unsecured}/faq`,
  },
  adCampaign: {
    validate: `${unsecured}/ad-campaigns/:adCampaignIdentifier`,
  },
  recaptcha: {
    verify: `${unsecured}/recaptcha/verify`,
  },
};
