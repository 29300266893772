import { ISwiperData, SwiperImageExtension } from '../../shared/interfaces/swiper.interfaces';

const carouselImagePathPrefix = 'assets/images/home-carousel';

const currentFormat = SwiperImageExtension.PNG;

export const CarouselElements: ISwiperData[] = [
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-World-Mall-202501-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-World-Mall-202501-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-World-Mall-202501-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-World-Mall-202501-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-World-Mall-202501-1920-1200${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A05s-202501-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A05s-202501-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A05s-202501-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A05s-202501-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A05s-202501-1920-1200${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/Samsung%20Galaxy%20A05s',
      queryParams: {
        szolgaltatas: 110083345,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-505-202501-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-505-202501-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-505-202501-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-505-202501-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-TCL-505-202501-1920-1200${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/TCL%20505',
      queryParams: {
        szolgaltatas: 110083345,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-100GB-202501-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-100GB-202501-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-100GB-202501-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-100GB-202501-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-100GB-202501-1920-1200${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-50GB-202501-600-360${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-50GB-202501-767-576${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-50GB-202501-959-768${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-50GB-202501-1199-960${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-50GB-202501-1920-1200${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
];
