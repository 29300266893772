import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { HeaderComponent } from './core/components/header/header.component';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxMaskModule } from 'ngx-mask';
import { GoogleMapsModule } from '@angular/google-maps';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { InitService } from './core/services/init-service/init.service';
import { FooterComponent } from './core/components/footer/footer.component';
import { PackageContainerDirective } from './modules/phone-detail/package-container.directive';

registerLocaleData(localeHu, 'hu-HU');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

export function initializeApp(initService: InitService) {
  return () => initService.initCall();
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, PackageContainerDirective],
  imports: [
    CoreModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    PerfectScrollbarModule,
    BrowserModule.withServerTransition({ appId: 'frontend' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ScrollingModule,
    BrowserTransferStateModule,
    NgxMaskModule.forRoot(),
    GoogleMapsModule,
    TransferHttpCacheModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'hu-HU' },
    CurrencyPipe,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [InitService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
