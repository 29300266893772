export enum ExposedEnvironmentVariable {
  KEYCLOAK_URL = 'KEYCLOAK_URL',
  KEYCLOAK_REALM = 'KEYCLOAK_REALM',
  KEYCLOAK_CLIENT_ID = 'KEYCLOAK_CLIENT_ID',
  KESZULEK_URL = 'KESZULEK_URL',
  MOBILHALO_URL = 'MOBILHALO_URL',
  NETFONE_URL = 'NETFONE_URL',
  PROFILKEP_URL = 'PROFILKEP_URL',
  RECAPTCHA_KEY = 'RECAPTCHA_KEY',
  MAPS_KEY = 'MAPS_KEY',
  VEAKEP_URL = 'VEAKEP_URL',
  KESZULEKEK_OG_URL = 'KESZULEKEK_OG_URL',
  USER_PORTAL_URL = 'USER_PORTAL_URL',
  CACHE_CONTROL = 'CACHE_CONTROL',
}

export const exposedEnvironmentVariables: string[] = [
  ExposedEnvironmentVariable.KEYCLOAK_URL,
  ExposedEnvironmentVariable.KEYCLOAK_REALM,
  ExposedEnvironmentVariable.KEYCLOAK_CLIENT_ID,
  ExposedEnvironmentVariable.KESZULEK_URL,
  ExposedEnvironmentVariable.MOBILHALO_URL,
  ExposedEnvironmentVariable.NETFONE_URL,
  ExposedEnvironmentVariable.PROFILKEP_URL,
  ExposedEnvironmentVariable.RECAPTCHA_KEY,
  ExposedEnvironmentVariable.MAPS_KEY,
  ExposedEnvironmentVariable.VEAKEP_URL,
  ExposedEnvironmentVariable.KESZULEKEK_OG_URL,
  ExposedEnvironmentVariable.USER_PORTAL_URL,
  ExposedEnvironmentVariable.CACHE_CONTROL,
];

export const defaultEnvironmentVariablesValues: { [key: string]: string } = {
  [ExposedEnvironmentVariable.KEYCLOAK_URL]: 'https://netfone.tst.netfone.hu/auth',
  [ExposedEnvironmentVariable.KEYCLOAK_REALM]: 'netfone-frontend',
  [ExposedEnvironmentVariable.KEYCLOAK_CLIENT_ID]: 'netfone-frontend-client',
  [ExposedEnvironmentVariable.NETFONE_URL]: 'http://localhost:4200',
  [ExposedEnvironmentVariable.MOBILHALO_URL]: 'http://localhost:4200',
  [ExposedEnvironmentVariable.KESZULEK_URL]: 'https://ose-static.netfone.hu/statikus-keszulek-kepek',
  [ExposedEnvironmentVariable.PROFILKEP_URL]: 'http://localhost:8080/profile_picture',
  [ExposedEnvironmentVariable.VEAKEP_URL]: 'https://ose-static.netfone.hu/vea_kapek',
  [ExposedEnvironmentVariable.KESZULEKEK_OG_URL]: 'https://ose-static.netfone.hu/og-images',
  [ExposedEnvironmentVariable.RECAPTCHA_KEY]: '6LdKlogqAAAAAKpl1stiEda0czz5T7XZJ0SpMz2-',
  [ExposedEnvironmentVariable.MAPS_KEY]: 'AIzaSyAhwC2VeXLf-6yxVVNzrI08EPle5CNeMS4',
  [ExposedEnvironmentVariable.USER_PORTAL_URL]: 'https://ugyfelkapu.netfone.hu',
  [ExposedEnvironmentVariable.CACHE_CONTROL]: 'true',
};
