<div class="footer">
  <div class="data-container">
    <div class="mini-netfone-logo">
      <img alt="Netfone logó" src="../../../../assets/images/netfone_logo_white.svg" />
    </div>
    <div class="top-section">
      <div class="link-container">
        <span class="link" routerLink="mobil-szolgaltatas"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Csomagok</span
        >
        <span class="link" routerLink="vezetekes-internet"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Otthoni Net</span
        >
        <span class="link" routerLink="keszulekek"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Készülékek</span
        >
        <span class="link" routerLink="sim-regisztracio"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Sim regisztráció</span
        >
        <span class="link" routerLink="kapcsolat"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Kapcsolat</span
        >
      </div>
      <div class="link-container">
        <span class="link" (click)="navigateAndScrollTo('/', 'colleagues')"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Munkatársak</span
        >
        <span class="link" routerLink="altalanos-szerzodesi-feltetelek"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />ÁSZF és kapcsolódó
          dokumentumok</span
        >
        <span class="link" routerLink="cofidis-aruhitel"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Cofidis áruhitel</span
        >
        <span class="link" routerLink="apn-beallitas"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />apn beállítás</span
        >
        <span class="link" routerLink="gyermekek-biztonsagos-internetezese"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />gyermekek biztonságos
          internetezése</span
        >
        <span class="link" routerLink="lefedettseg"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />mobil hálózati
          lefedettség</span
        >
      </div>
      <div class="link-container">
        <span class="link" routerLink="osszekapcsolasra-es-hivasvegzodtetesre-vonatkozo-altalanos-feltetelek"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Hívásvégződtetési
          díj</span
        >
        <span class="link" routerLink="percdijak"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />Nemzetközi díjak</span
        >
        <span class="link" routerLink="adatvedelmi-tajekoztato"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />adatvédelmi
          tájékoztató</span
        >
        <span class="link" routerLink="gyakori-kerdesek"
          ><img src="../../../../assets/images/footer-arrow.svg" class="link-arrow" alt="Link" />GYIK</span
        >
      </div>
    </div>
    <div class="middle-section">
      <img
        alt="Netfone logó"
        src="../../../../assets/images/netfone_logo_white.svg"
        class="netfone-logo"
        routerLink="/"
      />
      <div class="icons">
        <img
          alt="Facebook icon"
          href=""
          src="../../../../assets/images/white-facebook.svg"
          (click)="navigate('https://www.facebook.com/netfone.hu/')"
        />
        <img alt="Youtube icon" href="" src="../../../../assets/images/white-youtube.svg" />
        <img
          alt="Instagram icon"
          href=""
          src="../../../../assets/images/white-instagram.svg"
          (click)="navigate('https://www.instagram.com/netfone_official')"
        />
        <img alt="Share icon" (click)="openShareDialog()" src="../../../../assets/images/white-share.svg" />
      </div>
    </div>
    <div class="bottom-section">
      <div>
        <span class="bold">Netfone Telecom Kft. (C) {{ getCurrentYear() }} Minden jog fenntartva</span>
      </div>
    </div>
    <div class="mini-bottom-section">
      <div class="icons">
        <img
          alt="Facebook icon"
          (click)="navigate('https://www.facebook.com/netfone.hu/')"
          src="../../../../assets/images/white-facebook.svg"
        />
        <img alt="Youtube icon" href="" src="../../../../assets/images/white-youtube.svg" />
        <img
          alt="Instagram icon"
          (click)="navigate('https://www.instagram.com/netfone_official')"
          src="../../../../assets/images/white-instagram.svg"
        />
        <img alt="Share icon" (click)="openShareDialog()" src="../../../../assets/images/white-share.svg" />
      </div>
      <span class="bold">Netfone Telecom Kft.</span>
      <span>(C) {{ getCurrentYear() }} Minden jog fenntartva</span>
    </div>
  </div>
</div>

<ose-share-dialog></ose-share-dialog>
